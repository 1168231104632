import { USER_SIGN_UP, STUDENT_SIGN_IN, FORGET_PASSWORD } from "../ActionTypes";

const INIT_VALUES = {
    auth: [],
    student_auth: []
};

export const AuthReducer = (state = INIT_VALUES, action) => {
    switch (action.type) {
        case USER_SIGN_UP:
            return { ...state, auth: action.payload };

        case STUDENT_SIGN_IN:
            return { ...state, student_auth: {...action.payload}}
        
        default:
            return state;
    }
}


