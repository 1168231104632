import { GET_SUPPORT_DEPTS, GET_TICKETS, GET_TICKETS_REPLY, GET_TICKETS_REPLY_BY_ID } from "../ActionTypes";

const INIT_VALUES = {
    support_depts: [],
    tickets: [],
    from: "",
    to: "",
    total: "",
    prev_page: "",
    next_page: "",
    tickets_sent_by_id: [],
    tickets_reply_by_id: [],
};

export const SupportReducer = (state = INIT_VALUES, action) => {
    
    switch (action.type) {
        // get the action type, then pass the result to the right store/ initail value
        case GET_SUPPORT_DEPTS:
            return { ...state, support_depts: action.payload.data };

        case GET_TICKETS:
                return { 
                    ...state, 
                    tickets: action.payload.ticket_data, 
                    from: action.payload.from,
                    to: action.payload.to, 
                    total: action.payload.total, 
                    prev_page: action.payload.prev_page_url, 
                    next_page: action.payload.next_page_url, 
                };
        
        case GET_TICKETS_REPLY:
            return { 
                ...state, 
                tickets: action.payload.ticket_data, 
                from: action.payload.from,
                to: action.payload.to, 
                total: action.payload.total, 
                prev_page: action.payload.prev_page_url, 
                next_page: action.payload.next_page_url, 
            };

        case GET_TICKETS_REPLY_BY_ID:
            return { 
                ...state, 
                tickets_sent_by_id: action.payload.ticket_data,
                tickets_reply_by_id: action.payload.reply_data
            };
        
        default:
            return state;
    }
}


