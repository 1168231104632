import { ApiEndpoints, SERVER_REQUEST } from "../../shared";
import { GET_USER_DETAILS, GET_USERS, GET_DOCUMENTS, GET_REFEREES } from "../ActionTypes";

export const GetUsersDetails = (user_username, user_token, user_role) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.GET_USER_DETAILS+"/"+user_username+"/"+user_role, 'get', { user_username: user_username, user_token: user_token }, 'json' );
    
    let user_data = response.data.data;
    if(response){
        await dispatch({
            type: GET_USER_DETAILS,
            payload: user_data,
        });
    }
}

export const GetUsers = (user_username, page_number) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.GET_USERS+"?page="+page_number, 'get', '', 'json' );
    let user_data = response.data.data.data;
    let from        = response.data.data.from;
    let to          = response.data.data.to;
    let total       = response.data.data.total;
    let prev_page_url = response.data.data.prev_page_url;
    let next_page_url = response.data.data.next_page_url;

    if(prev_page_url != null){
        prev_page_url = prev_page_url.split('=')[1];
    }

    if(next_page_url != null){
        next_page_url = next_page_url.split('=')[1];
    }
    // dispatch the response from the api to the reducer. pass the data, from(number the data starts) to(where the data ends). for pagination
    
    await dispatch({
        type: GET_USERS,
        payload: {
            user_data: user_data, 
            from: from, 
            to: to, 
            total: total, 
            prev_page_url: prev_page_url, 
            next_page_url: next_page_url
        }
    });
}

export const GetDocuments = (user_username) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.GET_DOCUMENTS+"/"+user_username, 'get', '', 'json' );

    if(response) {
        let document_data = response.data.data;

        await dispatch({
            type: GET_DOCUMENTS,
            payload: document_data
        });
    }
}

export const GetReferees = (user_username) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.GET_REFEREES+"/"+user_username, 'get', '', 'json' );
    let ref_data = response.data.data;

    await dispatch({
        type: GET_REFEREES,
        payload: ref_data
    });
}

export const approveApplication = (username) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.APPROVE_APPLICATION, 'post', { username: username }, 'json');
    if(response){
        if (response.data.success) {
            // Success_Message("Login successful")
            // await dispatch({
            //     type: USER_SIGN_IN,
            //     payload: response.data.data,
            // });
            
            // const {
            //     Auth: { auth },
            // } = getState();

            // console.log(auth)
            // alert(auth)
            
            return {status: "success", data: response.data.data};
        } else {
            // Error_Message("invalid username or password")
            return {status: "error", data: response.data.data};
        }
    }else{
        return {status: "error", data: response.data.data};
    }
}

export const deleteApplication = (username) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.DELETE_APPLICATION, 'post', { username: username }, 'json');
    if(response){
        if (response.data.success) {
            // Success_Message("Login successful")
            // await dispatch({
            //     type: USER_SIGN_IN,
            //     payload: response.data.data,
            // });
            
            // const {
            //     Auth: { auth },
            // } = getState();

            // console.log(auth)
            // alert(auth)
            
            return {status: "success", data: response.data.data};
        } else {
            // Error_Message("invalid username or password")
            return {status: "error", data: response.data.data};
        }
    }else{
        return {status: "error", data: response.data.data};
    }
}