export const ApiEndpoints = {
    GET_USERS: 'get/users',
    GET_DOCUMENTS: 'get/documents',
    GET_REFEREES: 'get/referees',
    APPROVE_APPLICATION: 'approve/application',
    DELETE_APPLICATION: 'delete/application',

    MANAGER_SIGN_IN: 'manager/sign_in',
    GET_MANAGER_DETAILS: 'manager/get',


    

    
    USER_SIGN_UP: 'user/sign_up',
    UPDATE_USER_VERIFICATION_PHOTO: 'user/complete_signup',
    USER_SIGN_IN: 'user/signin',
    USER_FORGET_PASSWORD: 'user/forget_password',
    USER_CHECK_PASSWORD: 'user/check',
    USER_RESET_PASSWORD: 'user/reset_password',
    GET_USER_DETAILS: 'user/details',
    GET_PLAN_STUDENTS: 'my_plan/students',
    ADD_STUDENT: 'add/student',
    STUDENT_SIGN_IN: 'student/signin',
    UPDATE_STUDENT_VERIFICATION_PHOTO:  'student/complete_signup',
    GET_STUDENT_DETAILS:                'student/details',
    STUDENT_FORGET_PASSWORD:            'student/forget_password',
    GET_STUDENTS:                       'students',
    GET_MY_STUDENTS:                    'my_students',

   

    ADD_TEACHER:        'add/teacher',
    GET_TEACHERS:       'teachers',
    GET_ASSIGNED_TEACHER:   'assigned_teachers',
    ASSIGN_TEACHER:         'assign_teacher',
    GET_MY_TEACHERS:        'my_teachers',

    SAVE_TIMETABLE:         'save_timetable',
    GET_MY_TIMETABLE:       'my_timetable',

    GET_SUBSCRIPTION_PLANS: 'subscription_plans',
    PAY_SUBSCRIPTION_FEE: 'pay/subscription_fee',
    VERIFY_SUBSCRIPTION_PAYMENT: 'verify/subscription_fee',
    GET_MY_SUBSCRIPTIONS: 'get_subscriptions',

    RENEW_SUBSCRIPTION: 'renew/subscription',




    GET_COUNTRIES: 'get/countries',
    GET_INSTITUTIONS: 'get/institutions',
    GET_STATISTICS: 'get/statistics',
    GET_COURSE_CATEGORIES: 'get/course/categories',

    
    SIGN_IN: 'student/sign_in',
    FORGET_PASSWORD: 'student/forget_password',
    CHECK_PASSWORD: 'student/check',
    RESET_PASSWORD: 'student/reset_password',
    GET_STUDENT_DETAILS: 'student/get',
    UPDATE_FILE: 'update_file',
    UPDATE_DETAILS: 'update_details',
    GET_SUPPORT_DEPT: 'get/support/dept',
    SUBMIT_TICKET: 'submit/ticket',
    GET_TICKETS: 'get/tickets',
    GET_TICKETS_REPLY: 'get/tickets/reply',
    GET_TICKETS_REPLY_BY_ID: 'get/tickets/reply/by_id',

    SUBMIT_TICKET_REPLY: 'submit/ticket/reply',

    ADMIN_SIGN_IN: 'admin/sign_in',
    GET_ADMIN_DETAILS: 'admin/get',
    ADD_MANAGER: 'add/manager',
    GET_MANAGERS: 'get/managers',
    UPDATE_MANAGER_DETAILS: 'update/manager',
    
    UPDATE_STUDENT_DETAILS: 'update/student',

    TEACHER_SIGN_IN: 'teacher/sign_in',
    GET_TEACHER_DETAILS: 'get/teacher',
    UPDATE_TEACHER_DETAILS: 'update/teacher',

    GET_COURSES: 'get/courses',
    SAVE_CLASS_SCHEDULE: 'save/class/schedule',
    GET_CLASS_HISTORY: 'get/class/history',
    GET_MY_COURSES: 'get/my_courses',

    
}

export const AppEndpoints = {
    
}

