import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from 'redux-thunk';
import { AuthReducer, UserReducer, StudentReducer, GeneralReducer, SupportReducer, AdminReducer, ManagerReducer, CourseReducer, TeacherReducer, SubscriptionPlansReducer, TimetableReducer } from "./reducers";


const reducer = combineReducers({
    // this contains all reducers
    Auth: AuthReducer,
    User: UserReducer,


    Student: StudentReducer,
    General: GeneralReducer,
    Supports: SupportReducer,
    Admin: AdminReducer,
    Manager: ManagerReducer,
    Course: CourseReducer,
    Teacher: TeacherReducer,
    SubscriptionPlans: SubscriptionPlansReducer,
    Timetable: TimetableReducer
});

const initialState = {
    
};

const middleware = [thunk];

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;  