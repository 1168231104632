import { ApiEndpoints, INSERT_AUTH, INSERT_UPDATE_OR_DELETE_REQUEST } from "../../shared";
import { USER_SIGN_UP, UPDATE_USER_VERIFICATION_PHOTO, USER_SIGN_IN, USER_FORGET_PASSWORD, USER_CHECK_PASSWORD, USER_RESET_PASSWORD, ADD_STUDENT, STUDENT_SIGN_IN, UPDATE_STUDENT_VERIFICATION_PHOTO, STUDENT_FORGET_PASSWORD } from "../ActionTypes";

export const UserSignup = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.USER_SIGN_UP, 'post', { user: user_data }, 'json' );

    console.log(response)
    await dispatch({
        type: USER_SIGN_UP,
        payload: response.data,
    });

    let res = ""
    if (response.data.success) {
        return {status: "success", data: "Registration successful"};
    } else {
        const { email, password, username } = response.data.data;
        if(email){
            email.map(emailErr=>{ 
                res += emailErr+ " ";
            })
        } 
        if(username){
            username.map(usernameErr=>{ 
                res += usernameErr+ " ";
            })
        }
        if(password){
            password.map(passwordErr=>{ 
                res += passwordErr+ " ";
            })
        }else{
            res += "Please fill form correctly.";
            // return {status: "error", data: "Please fill form correctly"};
        }
        return {status: "error", data: res};
    }
    // else {
    //     return {status: "error", data: "An error occured"};
    // }
}

export const UsercompleteSignup = (user_data, user_username, user_role) => async (dispatch, getState) => {
    const response = await INSERT_UPDATE_OR_DELETE_REQUEST(ApiEndpoints.UPDATE_USER_VERIFICATION_PHOTO+"/"+user_username+"/"+user_role, 'put', { file_data: user_data }, 'json' );

    await dispatch({
        type: UPDATE_USER_VERIFICATION_PHOTO,
        payload: response.data,
    });
    
    if (response.data.success) {
        return {status: "success", data: "Registration successful"};
    }else {
        return {status: "error", data: "An error occured. Please retake photo"};
    }
}

export const UserSignin = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.USER_SIGN_IN, 'post', { user: user_data }, 'json');
    
    if(response){
        if (response.data.success) {
            // Success_Message("Login successful")
            await dispatch({
                type: USER_SIGN_IN,
                payload: response.data.data,
            });
            
            // const {
            //     Auth: { auth },
            // } = getState();

            // console.log(auth)
            // alert(auth)
            
            return {status: "success", data: response.data.data};
        } else {
            // Error_Message("invalid username or password")
            return {status: "error", data: "invalid username or password"};
        }
    }else{
        return {status: "error", data: "invalid username or password"};
    }
}

export const UserForgetPassword = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.USER_FORGET_PASSWORD, 'post', { user: user_data }, 'json');
    // alert("Check")
    // console.log(response)
    // console.log(response.data)
    
    if(response){
        if (response.data.success) {
            await dispatch({
                type: USER_FORGET_PASSWORD,
                payload: response.data.data,
            });
            
            const {
                Auth: { auth },
            } = getState();
            
            return {status: "success", data: auth};
        } else {
            // Error_Message("invalid username or password")
            return {status: "error", data: response.data.data};
        }
    }else{
        return {status: "error", data: "invalid username or password"};
    }
}

export const UserCheckPasswordCode = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.USER_CHECK_PASSWORD, 'post', { user: user_data }, 'json');
    
    if(response){
        if (response.status) {
            await dispatch({
                type: USER_CHECK_PASSWORD,
                payload: response.data,
            });
            
            return {status: "success", data: response.data};
        } else {
            // Error_Message("invalid username or password")
            return {status: "error", data: "invalid code"};
        }
    }else{
        return {status: "error", data: "invalid code"};
    }
}

export const UserResetPassword = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.USER_RESET_PASSWORD, 'post', { user: user_data }, 'json');
    
    if(response){
        if (response.status) {
            await dispatch({
                type: USER_RESET_PASSWORD,
                payload: response.data.data,
            });
            
            return {status: "success", data: response.data.data};
        } else {
            // Error_Message("invalid username or password")
            return {status: "error", data: "Password error"};
        }
    }else{
        return {status: "error", data: "Password error"};
    }
}

export const AddMyStudent = (user_data, user_username) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.ADD_STUDENT+"/"+user_username, 'post', { user: user_data }, 'json' );

    await dispatch({
        type: ADD_STUDENT,
        payload: response.data,
    });

    let res = ""
    if (response.data.success) {
        return {status: "success", data: "Registration successful"};
    } else {
        const { email, password, username } = response.data.data;
        if(email){
            email.map(emailErr=>{ 
                res += emailErr+ " ";
            })
        } 
        if(username){
            username.map(usernameErr=>{ 
                res += usernameErr+ " ";
            })
        }
        if(password){
            password.map(passwordErr=>{ 
                res += passwordErr+ " ";
            })
        }else{
            res += "Please fill form correctly.";
            // return {status: "error", data: "Please fill form correctly"};
        }
        return {status: "error", data: res};
    }
    // else {
    //     return {status: "error", data: "An error occured"};
    // }
}

export const StudentSignin = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.STUDENT_SIGN_IN, 'post', { user: user_data }, 'json');
    
    if(response){
        if (response.data.success) {
            // Success_Message("Login successful")
            await dispatch({
                type: STUDENT_SIGN_IN,
                payload: response.data.data,
            });
            
            const {
                Auth: { student_auth },
            } = getState();
            
            return {status: "success", data: student_auth};
        } else {
            // Error_Message("invalid username or password")
            return {status: "error", data: response.data.data};
        }
    }else{
        return {status: "error", data: "invalid username or password"};
    }
}

export const StudentCompleteSignup = (user_data, user_username, user_role) => async (dispatch, getState) => {
    const response = await INSERT_UPDATE_OR_DELETE_REQUEST(ApiEndpoints.UPDATE_STUDENT_VERIFICATION_PHOTO+"/"+user_username+"/"+user_role, 'put', { file_data: user_data }, 'json' );

    // await dispatch({
    //     type: UPDATE_STUDENT_VERIFICATION_PHOTO,
    //     payload: response.data,
    // });
    // alert(response.data)
    
    if (response.data.success) {
        return {status: "success", data: "Registration successful"};
    }else {
        return {status: "error", data: "An error occured. Please retake photo"};
    }
}

export const StudentForgetPassword = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.STUDENT_FORGET_PASSWORD, 'post', { user: user_data }, 'json');
    // alert("Check")
    console.log(response)
    console.log(response.data)
    
    if(response){
        if (response.data.success) {
            await dispatch({
                type: STUDENT_FORGET_PASSWORD,
                payload: response.data.data,
            });
            
            const {
                Auth: { auth },
            } = getState();
            
            return {status: "success", data: auth};
        } else {
            // Error_Message("invalid username or password")
            return {status: "error", data: response.data.data};
        }
    }else{
        return {status: "error", data: "invalid username or password"};
    }
}

export const SigninManager = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.MANAGER_SIGN_IN, 'post', { user: user_data }, 'json');
          
    if(response){
        if (response.data.success) {
            return {status: "success", data: response.data.data};
        } else {
            // Error_Message("invalid username or password")
            return {status: "error", data: "invalid username or password"};
        }
    }else{
        return {status: "error", data: "invalid username or password"};
    }
}









export const SigninAdmin = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.ADMIN_SIGN_IN, 'post', { user: user_data }, 'json');
    
    if(response){
        if (response.data.success) {
            // Success_Message("Login successful")
            // await dispatch({
            //     type: SIGN_IN,
            //     payload: response.data.data,
            // });
            
            const {
                Auth: { auth },
            } = getState();
            
            return {status: "success", data: auth};
        } else {
            // Error_Message("invalid username or password")
            return {status: "error", data: "invalid username or password"};
        }
    }else{
        return {status: "error", data: "invalid username or password"};
    }
}

export const SigninTeacher = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.TEACHER_SIGN_IN, 'post', { user: user_data }, 'json');
    
    if(response){
        if (response.data.success) {
            return {status: "success", data: response.data.data};
        } else {
            return {status: "error", data: "invalid username or password"};
        }
    }else{
        return {status: "error", data: "invalid username or password"};
    }
}