export const GET_USERS = 'get_users';
export const GET_DOCUMENTS = 'get_documents';
export const GET_REFEREES = 'get_ref';


export const USER_SIGN_UP   = 'user_sign_up';
export const UPDATE_USER_VERIFICATION_PHOTO = 'complete_user_signup';
export const USER_SIGN_IN           = 'user_signin';
export const USER_FORGET_PASSWORD   = 'user_forget_password';
export const USER_CHECK_PASSWORD    = 'user_check_password';
export const USER_RESET_PASSWORD    = 'reset_password';
export const GET_USER_DETAILS       = 'get_user_details'; 
export const GET_PLAN_STUDENTS      = 'get_plan_students';
export const ADD_STUDENT            = 'add_student';
export const STUDENT_SIGN_IN        = 'student_signin';
export const UPDATE_STUDENT_VERIFICATION_PHOTO  = 'complete_student_signup';
export const GET_STUDENT_DETAILS                = 'get_student_details';
export const STUDENT_FORGET_PASSWORD            = 'student_forget_password';
export const GET_STUDENTS                       = 'get_students';
export const GET_MY_STUDENTS                    = 'get_my_student';
export const ADD_TEACHER                        = 'add_teacher';
export const GET_TEACHERS                       = 'get_teachers';
export const GET_ASSIGNED_TEACHER               = 'assigned_teachers';
export const ASSIGN_TEACHER                     = 'assign_teacher';
export const GET_MY_TEACHERS                    = 'get_my_teachers';
export const SAVE_TIMETABLE                     = 'save_timetable';
export const GET_MY_TIMETABLE                   = 'get_my_timetable';












export const GET_COUNTRIES      = 'get_countries';
export const GET_INSTITUTIONS   = 'get_institutions';
export const GET_STATISTICS     = 'get_statistics';
export const GET_COURSE_CATEGORIES = 'get_course_categories'



export const SIGN_IN            = 'user_sign_in';
export const FORGET_PASSWORD    = 'user_forget_password';
export const CHECK_PASSWORD     = 'check_password';
export const RESET_PASSWORD     = 'reset_password';
export const USER_DETAILS           = 'user_details';
export const UPDATE_PROFILE_PICTURE = 'update_profile_picture';
export const GET_SUPPORT_DEPTS      = 'get_support_depts';
export const GET_TICKETS                = 'get_tickets';
export const GET_TICKETS_REPLY          = 'get_tickets_reply';
export const GET_TICKETS_REPLY_BY_ID    = 'get_tickets_reply_by_id';

export const ADMIN_DETAILS  = 'admin_details';
export const ADD_MANAGER    = 'add_manager';
export const GET_MANAGERS   = 'get_managers';
export const UPDATE_MANAGER_DETAILS = 'update_manager_details';

export const UPDATE_STUDENT_DETAILS = 'update_student_details';

export const MANAGER_DETAILS  = 'manager_details';

export const GET_COURSES = 'get_courses';
export const SAVE_CLASS_SCHEDULE    = 'save_class_schedule';
export const GET_CLASS_HISTORY      = 'get_class_history';
export const GET_MY_COURSES         = 'get_my_courses';

export const GET_TEACHER_DETAILS    = 'get_teacher_details';
export const UPDATE_TEACHER_DETAILS = 'update_teacher_details';

export const GET_SUBSCRIPTION_PLANS = 'get_subscription_plan';
export const PAY_SUBSCRIPTION_FEE   = 'pay_subscription_fee';
export const VERIFY_SUBSCRIPTION_PAYMENT = 'verify_subscription_payment';
export const GET_MY_SUBSCRIPTIONS        = 'get_subscription';

export const RENEW_SUBSCRIPTION     = 'renew_subscription';