import { GET_USER_DETAILS, GET_USERS, GET_DOCUMENTS, GET_REFEREES } from "../ActionTypes";

const INIT_VALUES = {
    user_data: [],
    user_data: [],
    users: [],
    from: "",
    to: "",
    total: "",
    prev_page: "",
    next_page: "",
    document_data: [],
    ref_data: [],
};

export const UserReducer = (state = INIT_VALUES, action) => {
    
    switch (action.type) {
        case GET_USER_DETAILS:
            return { ...state, user_data: action.payload };

        case GET_USERS:
            if(action.payload.from) {
                return { 
                    ...state, 
                    users: action.payload.user_data, 
                    from: action.payload.from,
                    to: action.payload.to, 
                    total: action.payload.total, 
                    prev_page: action.payload.prev_page_url, 
                    next_page: action.payload.next_page_url, 
                };
            }else {
                return { 
                    ...state, 
                    users: action.payload.user_data
                };
            }
        
        case GET_DOCUMENTS:
            return { ...state, document_data: action.payload };

        case GET_REFEREES:
           return { ...state, ref_data: action.payload };
        
        default:
            return state;
    }
}


