import { GET_PLAN_STUDENTS, GET_STUDENT_DETAILS, GET_MY_STUDENTS, GET_STUDENTS } from "../ActionTypes";

const INIT_VALUES = {
    my_plan_students: [],
    student_data: [],
    students: [],
    from: "",
    to: "",
    total: "",
    prev_page: "",
    next_page: "",
};

export const StudentReducer = (state = INIT_VALUES, action) => {
    
    switch (action.type) {
        case GET_PLAN_STUDENTS:
            return { ...state, my_plan_students: action.payload };

        case GET_STUDENT_DETAILS:
            return { ...state, student_data: action.payload };

        case GET_STUDENTS:
            if(action.payload.from) {
                return { 
                    ...state, 
                    students: action.payload.student_data, 
                    from: action.payload.from,
                    to: action.payload.to, 
                    total: action.payload.total, 
                    prev_page: action.payload.prev_page_url, 
                    next_page: action.payload.next_page_url, 
                };
            }else {
                return { 
                    ...state, 
                    students: action.payload.student_data
                };
            }

        case GET_MY_STUDENTS:
            if(action.payload.from) {
                return { 
                    ...state, 
                    students: action.payload.student_data, 
                    from: action.payload.from,
                    to: action.payload.to, 
                    total: action.payload.total, 
                    prev_page: action.payload.prev_page_url, 
                    next_page: action.payload.next_page_url, 
                };
            }else {
                return { 
                    ...state, 
                    students: action.payload.student_data
                };
            }
        
        default:
            return state;
    }
}


