import React from 'react';
import { Link } from "react-router-dom";

export const ManagerMenu = () => {
    return (
        <div>
            <nav className="mt-2">
                <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    {/* Add icons to the links using the .nav-icon class with font-awesome or any other icon font library */}
                    
                    <li className="nav-item">
                        <Link to="/view_users" className="nav-link">
                            <i className="nav-icon fas fa-th" />
                            <p>
                                Users
                            </p>
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}
