import { GET_MY_TIMETABLE } from "../ActionTypes";

const INIT_VALUES = {
    timetables: [],
};

export const TimetableReducer = (state = INIT_VALUES, action) => {
    
    switch (action.type) {
        case GET_MY_TIMETABLE:
            return { ...state, timetables: action.payload };

        default:
            return state;
    }
}


