import { ApiEndpoints, SERVER_REQUEST, INSERT_UPDATE_OR_DELETE_REQUEST } from "../../shared";
import { ADMIN_DETAILS, ADD_MANAGER, GET_MANAGERS, UPDATE_MANAGER_DETAILS, GET_STUDENTS, UPDATE_STUDENT_DETAILS } from "../ActionTypes";

export const GetAdminDetails = (user_username, user_token, user_role) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.GET_ADMIN_DETAILS+"/"+user_username+"/"+user_role, 'get', { user_username: user_username, user_token: user_token }, 'json' );

    await dispatch({
        type: ADMIN_DETAILS,
        payload: response.data,
    });
    
    if (response.status === 200) {
        return { status: "success", data: response.data };
    } else {
        return { status: "error", data: "An error occured" };
    }
}

export const AddManagerDetails = (user_data, user_username, user_token, user_role) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.ADD_MANAGER+"/"+user_username+"/"+user_role, 'post', { user_username: user_username, user_token: user_token, user_data: user_data }, 'json' );

    await dispatch({
        type: ADD_MANAGER,
        payload: response.data,
    });
    
    if (response.status === 201) {
        return { status: "success", data: response.data };
    } else {
        return { status: "error", data: "An error occured" };
    }
}

export const GetManagers = (user_username, user_token, user_role, page_number) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.GET_MANAGERS+"/"+user_username+"/"+user_role+"?page="+page_number, 'get', { user_username: user_username, user_token: user_token, user_role: user_role }, 'json' );
    
    let manager_data = response.data.data.data;
    let from        = response.data.data.from;
    let to          = response.data.data.to;
    let total       = response.data.data.total;
    let prev_page_url = response.data.data.prev_page_url;
    let next_page_url = response.data.data.next_page_url;

    if(prev_page_url != null){
        prev_page_url = prev_page_url.split('=')[1];
    }

    if(next_page_url != null){
        next_page_url = next_page_url.split('=')[1];
    }
    // dispatch the response from the api to the reducer. pass the data, from(number the data starts) to(where the data ends). for pagination
    await dispatch({
        type: GET_MANAGERS,
        payload: {
            manager_data: manager_data, 
            from: from, 
            to: to, 
            total: total, 
            prev_page_url: prev_page_url, 
            next_page_url: next_page_url
        }
    });
}

export const UpdateManagerDetails = (user_data, user_username, user_token, user_role) => async (dispatch, getState) => {
    const response = await INSERT_UPDATE_OR_DELETE_REQUEST(ApiEndpoints.UPDATE_MANAGER_DETAILS+"/"+user_username+"/"+user_role, 'put', { user_username: user_username, user_token: user_token, user_data: user_data }, 'json' );

    await dispatch({
        type: UPDATE_MANAGER_DETAILS,
        payload: response.data,
    });
    
    if (response.status === 201) {
        return { status: "success", data: response.data };
    } else {
        return { status: "error", data: "An error occured" };
    }
}

export const UpdateStudentDetails = (user_data, user_username, user_token, user_role) => async (dispatch, getState) => {
    const response = await INSERT_UPDATE_OR_DELETE_REQUEST(ApiEndpoints.UPDATE_STUDENT_DETAILS+"/"+user_username+"/"+user_role, 'put', { user_username: user_username, user_token: user_token, user_data: user_data }, 'json' );

    await dispatch({
        type: UPDATE_STUDENT_DETAILS,
        payload: response.data,
    });
    
    if (response.status === 201) {
        return { status: "success", data: response.data };
    } else {
        return { status: "error", data: "An error occured" };
    }
}