import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

export const Header = () => {
    let history = useHistory();

    const logout = async () => {
        localStorage.setItem("isAuth", false);
        if(localStorage.getItem('role') === "user"){
            history.push('/user_signin');
        }else if(localStorage.getItem('role') === "student"){
            history.push('/');
        }else if(localStorage.getItem('role') === "teacher"){
            history.push('/teacher_signin');
        }else if(localStorage.getItem('role') === "manager"){
            history.push('/manager_signin');
        }else if(localStorage.getItem('role') === "superadministrator"){
            history.push('/admin_signin');
        }else {
            history.push('/');
        }
    }

    return (
        <div>
            {/* Navbar */}
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                {/* Left navbar links */}
                {/* <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars mydas-secondary" /></a>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        <a href="https://lms.kiu.ac.ug" target=" _blank" className="nav-link"> Home</a>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        <a href="https://lms.kiu.ac.ug/my/" target=" _blank" className="nav-link">About</a>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        <a href="https://codeesa.kiu.ac.ug/mydas.php" target=" _blank" className="nav-link">Contact</a>
                    </li>
                </ul> */}
                {/* SEARCH FORM */}
                {/* <form className="form-inline ml-3">
                    <div className="input-group input-group-sm">
                        <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                        <div className="input-group-append">
                            <button className="btn btn-navbar" type="submit">
                                <i className="fas fa-search" />
                            </button>
                        </div>
                    </div>
                </form> */}
                {/* Right navbar links */}
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                            <i className="fas fa-expand-arrows-alt" />
                        </a>
                    </li>
                    <li className="nav-item" onClick={() => logout()}>
                        <span className="nav-link" data-widget="control-sidebar" data-slide="true" role="button">
                            <i className="fas fa-sign-out-alt mydas-secondary" /> 
                            <span className="mydas-primary" style={{fontWeight: "500"}}> Logout</span>
                        </span>
                    </li>
                </ul>
            </nav>
            {/* /.navbar */}
        </div>
    )
}
