import { MANAGER_DETAILS, GET_MANAGERS, UPDATE_MANAGER_DETAILS, GET_STUDENTS, UPDATE_STUDENT_DETAILS } from "../ActionTypes";

const INIT_VALUES = {
    manager: [],
    from: "",
    to: "",
    total: "",
    prev_page: "",
    next_page: "",
};

export const ManagerReducer = (state = INIT_VALUES, action) => {
    
    switch (action.type) {
        case MANAGER_DETAILS:
            return { ...state, admin: action.payload };
        
        case UPDATE_MANAGER_DETAILS:
            return {
                ...state,
                manager: action.payload.manager_data, 
            }  

        default:
            return state;
    }
}


