import React from 'react'

export const Footer = () => {
    return (
        <div>
            <footer className="main-footer">
                <strong>Copyright © 2022 <a href="https://namp.ng" target=" _blank">
                    <span className="mydas-secondary">Nigerian Association Of Medical Physicists</span></a>. </strong>
                 All rights reserved.
                {/* <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 3.1.0-rc
                </div> */}
            </footer>
        </div>
    )
}
