import { GET_COURSES, GET_CLASS_HISTORY, GET_MY_COURSES } from "../ActionTypes";

const INIT_VALUES = {
    courses: [],
    from: "",
    to: "",
    total: "",
    prev_page: "",
    next_page: "",

    class_history_data: [],
    class_history_from: "",
    class_history_to: "",
    class_history_total: "",
    class_history_prev_page_url: "",
    class_history_next_page_url: "",

    my_courses: [],
    my_courses_from: "",
    my_courses_to: "",
    my_courses_total: "",
    my_courses_prev_page: "",
    my_courses_next_page: "",
};

export const CourseReducer = (state = INIT_VALUES, action) => {
    
    switch (action.type) {

        case GET_COURSES:
            return { 
                ...state, 
                courses: action.payload.course_data, 
                from: action.payload.from,
                to: action.payload.to, 
                total: action.payload.total, 
                prev_page: action.payload.prev_page_url, 
                next_page: action.payload.next_page_url, 
            };

        case GET_CLASS_HISTORY:
            return {
                ...state, 
                class_history_data: action.payload.class_history_data, 
                class_history_from: action.payload.from,
                class_history_to: action.payload.to, 
                class_history_total: action.payload.total, 
                class_history_prev_page_url: action.payload.prev_page_url, 
                class_history_next_page_url: action.payload.next_page_url,
            }

        case GET_MY_COURSES:
            return {
                ...state, 
                my_courses: action.payload.course_data, 
            }
        
        default:
            return state;
    }
}


