import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { ManagerMenu } from "./sidebarMenu/ManagerMenu";

export const Menu = () => {
    let menu = [];

  
    if(localStorage.getItem('role') == "manager"){
        menu = <ManagerMenu />
    }

    return (
        <div>
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                {/* Brand Logo */}
                <Link to="/dashboard" className="brand-link" style={{background: "#f8f9fa"}}>
                    <img src="images/logo.png" alt="MYDAS Logo" className="brand-image elevation-3" style={{marginTop: "-10px", maxHeight: "50px"}} />
                    <span className="brand-text font-weight-light">.</span>
                </Link>
                {/* Sidebar */}
                <div className="sidebar" style={{background: "#030b25"}}>
                    {/* Sidebar user panel (optional) */}
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        {/* <div className="image">
                            <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
                        </div> */}
                        <div className="info">
                            <a href="#" className="d-block">{localStorage.getItem('username')}</a>
                        </div>
                    </div>
                    {/* SidebarSearch Form */}
                    {/* <div className="form-inline">
                        <div className="input-group" data-widget="sidebar-search">
                            <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                            <div className="input-group-append">
                                <button className="btn btn-sidebar">
                                    <i className="fas fa-search fa-fw" />
                                </button>
                            </div>
                        </div>
                    </div> */}
                    {/* Sidebar Menu */}
                        { menu }
                    {/* /.sidebar-menu */}
                </div>
                {/* /.sidebar */}
            </aside>
        </div>
    )
}

