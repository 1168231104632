import { GET_SUBSCRIPTION_PLANS, PAY_SUBSCRIPTION_FEE, VERIFY_SUBSCRIPTION_PAYMENT, GET_MY_SUBSCRIPTIONS } from "../ActionTypes";

const INIT_VALUES = {
    my_plans: [],
    student_count: [],
};

export const SubscriptionPlansReducer = (state = INIT_VALUES, action) => {
    
    switch (action.type) {
        case GET_SUBSCRIPTION_PLANS:
            return { ...state, plans: action.payload.data };

        case GET_MY_SUBSCRIPTIONS:
            return { ...state, 
                my_plans: action.payload.my_subscriptions,
                student_count: action.payload.student_count,
            };
        
        default:
            return state;
    }

    
}


