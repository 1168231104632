import { ADMIN_DETAILS, GET_MANAGERS, UPDATE_MANAGER_DETAILS, GET_STUDENTS, UPDATE_STUDENT_DETAILS } from "../ActionTypes";

const INIT_VALUES = {
    admin: [],
    managers: [],
    from: "",
    to: "",
    total: "",
    prev_page: "",
    next_page: "",
};

export const AdminReducer = (state = INIT_VALUES, action) => {
    
    switch (action.type) {
        case ADMIN_DETAILS:
            return { ...state, admin: action.payload.data };

        case GET_MANAGERS:
            return { 
                ...state, 
                managers: action.payload.manager_data, 
                from: action.payload.from,
                to: action.payload.to, 
                total: action.payload.total, 
                prev_page: action.payload.prev_page_url, 
                next_page: action.payload.next_page_url, 
            };

        case UPDATE_STUDENT_DETAILS:
            return {
                ...state,
                students: action.payload.student_data, 
            }  
        
        
        default:
            return state;
    }
}


