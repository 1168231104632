import React from 'react';
import { Route,Redirect } from "react-router-dom";

export const PrivateRoute = ({children, ...rest}) => {
    if(localStorage.getItem('isAuth') == "true"){
        const isAuth = true;
        return (<Route {...rest} render={()=>isAuth?(children):(<Redirect to={'/'}/>)}/>);
    }else if(localStorage.getItem('isAuth') == "false"){
        const isAuth = false;
        return (<Route {...rest} render={()=>isAuth?(children):(<Redirect to={'/'}/>)}/>);
    }
}
