import logo from './logo.svg';
import './App.css';
import {Dashboard} from './pages/Dashboard';
import  { PrivateRoute }  from "./components/PrivateRoute";
import { PublicRoute } from "./components/PublicRoute";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// to animate router transition
import { AnimatedSwitch } from 'react-router-transition';

import { ViewUsers } from "./pages/ViewUsers";
import { Offline, Online } from "react-detect-offline";
import Loader from "react-loader-spinner";


import { ManagerSignin } from "./pages/manager/ManagerSignin";
import { ManagerProfile } from "./pages/manager/ManagerProfile";

function App() {

  return (
    <div>
      {/* <Online> */}
        <Router>
          {/* <Switch> */}
          <AnimatedSwitch
            atEnter={{ opacity: 0 }}
            atLeave={{ opacity: 0 }}
            atActive={{ opacity: 1 }}
            className="switch-wrapper"
          >   
              <PublicRoute exact path="/"  component={ManagerSignin} />
              <PrivateRoute exact path="/view_users">
                <ViewUsers />
              </PrivateRoute>

              {/* <PublicRoute exact path="/manager_profile"  component={ManagerProfile} /> */}
              <PrivateRoute exact path="/manager_profile">
                <ManagerProfile />
              </PrivateRoute>

          </AnimatedSwitch>
          {/* </Switch> */}
        </Router>
      {/* </Online> */}
      <Offline>
        <div style={{position: "fixed", height:"100%", width:"100%"}}>
            <div style={{position: "fixed", top:"73%", left:"75%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px", textAlign: "center" }}>
                <Loader
                    type="MutatingDots"
                    color="#183287"
                    secondaryColor="#ff9700"
                    height={100}
                    width={100}
                    visible="true"
                    // timeout={3000} //3 secs
                />
                <img src="images/logo.png" alt="MYDAS Logo" className="brand-image elevation-3" style={{maxHeight: "40px", width: "auto", marginBottom: "20px"}} />
                <h6 style={{color: "#ff9700"}}>Please check your internet connection</h6>
            </div>
        </div>
      </Offline>

    </div>
    
  );
}

export default App;