import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GetUsers, GetCountries, GetDocuments, GetReferees, UpdateStudentDetails, approveApplication, deleteApplication } from "../redux/actions";
import {Header} from '../includes/Header';
import {Menu} from '../includes/Menu';
import {Footer} from '../includes/Footer';
import { AesEncrypt, AesDecrypt } from 'aes';
import { Success_Message, Error_Message } from "../shared";
import { Row, Col, Button, ListGroupItem } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import axios from 'axios';
import NaijaStates from 'naija-state-local-government';
import ReactInputDateMask from 'react-input-date-mask';

export const ViewUsers = () => {
    let history = useHistory();
    
    let user_username   = localStorage.getItem('username');
    let user_token      = localStorage.getItem('token');
    let user_role               = localStorage.getItem('role');
    let res = {};
    let [x, setX] = useState(0);
    // let x = 0; 
    let y = 0; let z = 0;
    const [showLoaderDiv, setShowLoaderDiv] = useState("none");
    const [loaderVisible, setLoaderVisible] = useState(false);

    const dispatch = useDispatch();
    const User = useSelector((state) => state.User);

    const General = useSelector((state) => state.General);
    // const { institutions }  = General;
    const [authToken, setAuthToken] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [lgas, setLGA] = useState([])
    const [LGADrowdown, setLGADrowdown] = useState(true);
    const [profile_picture, setProfileImage] = useState("../../images/default-avatar.png");

    const field_activities = ['Academic', 'Diagnostic Radiology', 'Nuclear Medicine', 'Radiotherapy', 'Student', 'Others'];

    const [degreesObtained, setDegreesObtained] = useState([]);
    const [bscChecked, setBscChecked] = useState(false);
    const [mscChecked, setMscChecked] = useState(false);
    const [drChecked, setDrChecked] = useState(false);

    const { users }     = User;
    const { from }      = User;
    const { to }        = User;
    const { total }     = User;
    const { prev_page } = User;
    const { next_page } = User;
    const { document_data } = User;
    const { ref_data } = User;

    const [user_data, setUser] = useState({
        title: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        username: '',
        email: '',
        zip_code: '',
        telephone: '',
        gender: '',
        nationality: '',
        country_of_residence: '',
        district_province_state_of_origin: '',
        lga_of_origin: '',
        contact_address: '',
        dob: '',
        degrees_obtained: [],
        field_activity: '',
        profile_picture: '',
        membership_type: '',
        status: '',
    });

    useEffect(() => {
        const getDetails = async () => {
            setShowLoaderDiv("block"); setLoaderVisible(true);
            // get users the number 1 is the page number to fetch
            await dispatch(GetUsers(user_username, "1"));
            await dispatch(GetCountries(user_token));
            setShowLoaderDiv("none"); setLoaderVisible(false);
        }
        getDetails();

    }, [user_data]);

    useEffect(() => {
        getToken();
    }, [])

    const getToken = () => {
        const config = {
            headers: ({
                "Accept": "application/json",
                "api-token": "0-LfT03Ky6HzWVoVDT9Nj5jV7lkn7M2omvS-lBYEaZGhV1IvE3nbk2_9boqVlQo1X2Q",
                "user-email": "mendes.paul28@gmail.com"
            })
        };

        const url = "https://www.universal-tutorial.com/api/getaccesstoken";

        axios.get(url, config)
        .then(res=> {
            getCountries(res.data.auth_token)
            setAuthToken(res.data.auth_token)
        })
        .catch(err=> console.log(err))
    }

    const getCountries = (auth_token) => {
        const config = {
            headers: ({
                "Authorization": `Bearer ${auth_token}`,
                "Accept": "application/json"
            })
        };

        const url = "https://www.universal-tutorial.com/api/countries/";

        axios.get(url, config)
        .then(res=> {
            setCountries(res.data)
        })
        .catch(err=> console.log(err))
    }

    const getStates = (country) => {
        // if(country === 'Nigeria') {
            setLGADrowdown(true)
            const config = {
                headers: ({
                    "Authorization": `Bearer ${authToken}`,
                    "Accept": "application/json"
                })
            };

            const url = `https://www.universal-tutorial.com/api/states/${country}`;

            axios.get(url, config)
            .then(res=> {
                setStates(res.data)
            })
            .catch(err=> console.log(err))
        // }else {
        //     setLGADrowdown(false)
        // }
    }

    const getLGA = (state) => {
        const res = NaijaStates.lgas(state);
        setLGA(res.lgas)
    }
    
    const viewUser = async (title, first_name, last_name, middle_name, username, email, zip_code, telephone, gender, nationality, country_of_residence, district_province_state_of_origin, lga_of_origin, contact_address, dob, degrees_obtained, field_activity, profile_picture, signature, membership_type, status, created_at) => {
        
        await dispatch(GetDocuments(username));
        await dispatch(GetReferees(username));
        if(nationality !== null) {await getStates(nationality);}
        if(district_province_state_of_origin !== null) {await getLGA(district_province_state_of_origin);}
        if(degrees_obtained !== null) {await degreesCheck(degrees_obtained);}

        if(profile_picture == "" || profile_picture == null) {
        }else {
          setProfileImage(profile_picture)
        }

        loadSignature(signature);

        setUser(user_data => ({ ...user_data, title: title, first_name: first_name, last_name: last_name, middle_name: middle_name, username: username, email: email, zip_code: zip_code, telephone: telephone, gender: gender, nationality: nationality, country_of_residence: country_of_residence, district_province_state_of_origin: district_province_state_of_origin, lga_of_origin: lga_of_origin, contact_address: contact_address, dob: dob, degrees_obtained: degrees_obtained, field_activity: field_activity, profile_picture: profile_picture, membership_type: membership_type, status: status, created_at: created_at }))
    }

    // const handleChange = async (e) => {
    //     const { name, value } = e.target;
    //     setUser(user_data => ({ ...user_data, [name]: value }));
    // }
    const handleChange = async (e) => {
        const { name, value } = e.target;
        if(name === 'nationality') {
            getStates(value)
            setUser(user_data => ({ ...user_data, [name]: value }));
        }else if(name === 'district_province_state_of_origin') {
            if(user_data.nationality === 'Nigeria') {
                setLGADrowdown(true)
                getLGA(value)
            }else {
                setLGADrowdown(false)
            }
            setUser(user_data => ({ ...user_data, [name]: value }));
        }else {
            setUser(user_data => ({ ...user_data, [name]: value }));
        }
    }

    function handleChangeDOB(e) {
        setUser(user_data => ({ ...user_data, dob: e }));
    }

    const UpdateDetails = async (e) => {
        e.preventDefault();
        let dd = user_data.dob.split('/')[0];
        let mm = user_data.dob.split('/')[1];
        let yy = user_data.dob.split('/')[2];
        let dob = dd+"-"+mm+"-"+yy;
        await setUser(user_data => ({ ...user_data, dob: dob }));

        if (
            user_data.title == "select" || user_data.first_name == "" || user_data.last_name == "" ||  user_data.middle_name == "" || user_data.username == "" || user_data.email == "" || user_data.zip_code == "" || user_data.telephone == "" || user_data.gender == "" || user_data.nationality == "" || user_data.country_of_residence == "" || user_data.district_province_state_of_origin == "" || user_data.lga_of_origin == "" || user_data.contact_address == "" || user_data.dob == "" || user_data.degrees_obtained == "" || user_data.field_activity == "" ||
            user_data.title == null || user_data.first_name == null || user_data.last_name == null ||  user_data.middle_name == null || user_data.username == null || user_data.email == null || user_data.zip_code == null || user_data.telephone == null || user_data.gender == null || user_data.nationality == null || user_data.country_of_residence || user_data.district_province_state_of_origin == null || user_data.lga_of_origin == null || user_data.contact_address || user_data.dob == null || user_data.degrees_obtained == null || user_data.field_activity == null
        ) {
            Error_Message("Please fill all required fields")
        }else{
            setShowLoaderDiv("block"); setLoaderVisible(true);
            // const user_data1 = AesEncrypt(user_data, 'where do you go when you by yourself' );
            res = await dispatch(UpdateStudentDetails(user_data, user_username, user_token, user_role));
            alertMessage(res);
            // history.push('/add_student')
            await dispatch(GetUsers(user_username, user_token, user_role, "1"));
            setShowLoaderDiv("none"); setLoaderVisible(false);
        }
    }

    const alertMessage = async (res) => {
        if(res.status === "success"){ 
            Success_Message(res.data) 
            setUser(user_data => ({ ...user_data, status: 2 }));
            window.location.reload();
        }else{
            Error_Message("Failed. Try again. Make sure your internet is stable.")
        }
    }

    const handlePageChange = async (page_number) => {
        if(page_number > 0) {
            x = ((page_number - 1) * 10);
            setX(x);
        }
        
        setShowLoaderDiv("block"); setLoaderVisible(true);
        await dispatch(GetUsers(user_username, page_number));
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }

    const viewDocument = async (document) => {
        window.open(document);
    }

    const approveApp = async (username) => {
        res = await dispatch(approveApplication(username));
        await alertMessage(res);
    }

    const deleteUser = async (username) => {
        res = await dispatch(deleteApplication(username));
        await alertMessage(res);
    }

    const degreesCheck = async(degrees) => {
        const degreesArray = JSON.parse(degrees);
        const bscExist = degreesArray.includes('Bachelor of Science/Technology');
        if(bscExist) {
            setBscChecked(true);
        }

        const mscExist = degreesArray.includes('Master of Science/Technology');
        if(mscExist) {
            setMscChecked(true);
        }

        const drExist = degreesArray.includes('Doctor of Philosophy');
        if(drExist) {
            setDrChecked(true);
        }

        // setUser(user_data1 => ({ ...user_data1, degrees_obtained: degreesArray }));
    }

    const handleChangeDegreesObtained = async (e) => {
        const { name, checked, value } = e.target;
        if(checked) {
            const exist = degreesObtained.includes(value);
            if(exist) {
                const index = degreesObtained.indexOf(value);
                degreesObtained.splice(index, 1);
                await await setUser(user_data => ({ ...user_data, degrees_obtained: JSON.stringify(degreesObtained) }));
            }else {
                degreesObtained.push(value);
                await await setUser(user_data => ({ ...user_data, degrees_obtained: JSON.stringify(degreesObtained) }));
            }
        }else {
            const index = degreesObtained.indexOf(value);
            degreesObtained.splice(index, 1);
            await await setUser(user_data => ({ ...user_data, degrees_obtained: JSON.stringify(degreesObtained) }));
        }

        if(name === 'bachelor_of_science_technology') {
            setBscChecked(!bscChecked);
        }else if(name === 'master_of_science_technology') {
            setMscChecked(!mscChecked);
        }else if(name === 'doctor_of_philosophy') {
            setDrChecked(!drChecked);
        }
    }

    const loadSignature = (signature) => {
        let signatureImage = document.createElement("img");
        signatureImage.src = signature;
        // signatureImage.width = "150";
        signatureImage.height = "55";
        document.querySelector("#imageContainer").innerHTML = signatureImage.outerHTML;
    }

    return (
        <div className="wrapper">
            <Header/>
            <Menu/>
                <div className="content-wrapper">
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <div style={{position: "fixed", height:"100%", width:"100%", display: showLoaderDiv, zIndex: "1500"}}>
                        <div style={{position: "fixed", top:"50%", left:"46%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px" }}>
                            <Loader
                                type="MutatingDots"
                                color="#183287"
                                secondaryColor="#ff9700"
                                height={100}
                                width={100}
                                visible={loaderVisible}
                                // timeout={3000} //3 secs
                            />
                            <img src="images/logo.png" alt="MYDAS Logo" className="brand-image elevation-3" style={{maxHeight: "40px", width: "auto", marginBottom: "20px"}} />
                            <h6 style={{color: "#183287"}}>Loading...</h6>
                        </div>
                    </div>
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Users</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard" style={{color: "#ff9700"}}>Home</Link>
                                </li>
                                <li className="breadcrumb-item active">Users</li>
                                </ol>
                            </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>

                    <section className="content">
                        <div className="row">
                            {/* /.col */}
                            <div className="col-md-12">
                                <div className="card card-primary card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title">List of Users</h3>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body p-0">
                                        <div className="mailbox-controls">

                                            <div className="float-right">
                                            {from}-{to}/{total} 
                                                <div className="btn-group"  style={{padding: "10px"}}>
                                                    <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(prev_page)}>
                                                        <i className="fas fa-chevron-left" />
                                                    </button>
                                                    <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(next_page)}>
                                                        <i className="fas fa-chevron-right" />
                                                    </button>
                                                </div>
                                                {/* /.btn-group */}
                                            </div>
                                            {/* /.float-right */}
                                        </div>
                                        <div className="table-responsive mailbox-messages">
                                            <table id="example1" className="table table-hover table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>S/N</th>
                                                        <th>Title</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Username</th>
                                                        <th>Email</th>
                                                        <th>Status</th>
                                                        <th>Date Joined</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                    {users && users.map((user) => {
                                                        let status = "";
                                                        let color = ""
                                                        if(user.created_at){
                                                            user.created_at = user.created_at.split('T')[0]
                                                        }
                                                        if(user.status == 0) {
                                                            status = "Account not activated"
                                                            color = "firebrick"
                                                        }else if(user.status == 1) {
                                                            status = "Awaiting admin approval"
                                                            color = "firebrick"
                                                        }else if(user.status == 2) {
                                                            status = "Payment not made"
                                                            color = "firebrick"
                                                        }else if(user.status == 3) {
                                                            status = "Payment made"
                                                            color = "green"
                                                        }
                                                        
                                                        if(user.membership_type === 1) {
                                                            user.membership_type = 'Fellowship';
                                                        }else if(user.membership_type === 2) {
                                                            user.membership_type = 'Full Membership';
                                                        }else if(user.membership_type === 3) {
                                                            user.membership_type = 'Associate Membership';
                                                        }else if(user.membership_type === 4) {
                                                            user.membership_type = 'Student Membership';
                                                        }else if(user.membership_type === 5) {
                                                            user.membership_type = 'Affiliate Membership';
                                                        }else if(user.membership_type === 6) {
                                                            user.membership_type = 'International Membership';
                                                        }
                                                        return(
                                                            <tr  
                                                                data-toggle="modal" 
                                                                data-target="#view-user" 
                                                                style={{cursor: "pointer", fontSize: "14px"}} 
                                                                onClick={() => viewUser(user.title, user.first_name, user.last_name, user.middle_name, user.username, user.email, user.zip_code, user.telephone, user.gender, user.nationality, user.country_of_residence, user.district_province_state_of_origin, user.lga_of_origin, user.contact_address, user.dob, user.degrees_obtained, user.field_activity, user.profile_picture, user.signature, user.membership_type, user.status, user.created_at)}
                                                            >
                                                                <td className="mailbox-name">{++x}</td>
                                                                <td className="mailbox-name">{user.username}</td>
                                                                <td className="mailbox-name">
                                                                    {user.title}
                                                                </td>
                                                                <td className="mailbox-name">
                                                                    {user.last_name}
                                                                </td>
                                                                <td className="mailbox-name">
                                                                    {user.first_name}
                                                                </td>
                                                                <td className="mailbox-name">
                                                                    {user.email}
                                                                </td>
                                                                <td className="mailbox-name">
                                                                    <span style={{color: color}}>{status}</span>
                                                                </td>
                                                                <td className="mailbox-date">{user.created_at}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                    
                                                </tbody>
                                            </table>
                                            {/* /.table */}
                                        </div>
                                        {/* /.mail-box-messages */}
                                    </div>
                                    {/* /.card-body */}

                                    <div className="card-footer p-0">
                                        <div className="mailbox-controls">
                                            <div className="float-right">
                                            {from}-{to}/{total}
                                            <div className="btn-group" style={{padding: "10px"}}>
                                                <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(prev_page)}>
                                                <i className="fas fa-chevron-left" />
                                                </button>
                                                <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(next_page)}>
                                                <i className="fas fa-chevron-right" />
                                                </button>
                                            </div>
                                            {/* /.btn-group */}
                                            </div>
                                            {/* /.float-right */}
                                        </div>
                                    </div>
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>

                        {/* View User modal */}
                        <div className="modal fade" id="view-user">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="support_subject"></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                    </button>
                                    
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label style={{fontSize: '18px', fontWeight: '500'}}>Personal Details</label>
                                        </div>
                                        <div className='col-md-4' style={{color: 'firebrick', textAlign: 'center', fontSize: '16px', fontWeight: '500'}}>
                                            {user_data.membership_type}
                                        </div>
                                        <div className='col-md-4'>
                                            { user_data.status == 1 ?
                                                <button 
                                                    type="submit" 
                                                    class="btn bg-gradient-success btn-sm"
                                                    onClick={() => approveApp(user_data.username)}
                                                    style={{float: "right"}}
                                                >Approve Application</button> : 
                                                <button 
                                                    type="submit" 
                                                    class="btn bg-gradient-success btn-sm"
                                                    style={{float: "right"}}
                                                    disabled={true}
                                                >Application Approved</button>
                                            }
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <form onSubmit={UpdateDetails}>
                                        <Row style={{marginBottom: "25px"}}>
                                            <Col md={6} xs={6}>
                                                <img 
                                                    className="profile-user-img img-fluid" 
                                                    src={profile_picture} 
                                                    alt="User profile" 
                                                    style={{width: "150px"}}
                                                />
                                            </Col>
                                            <Col md={5} xs={5}>
                                                <div className="card card-primary card-outline">
                                                    <div style={{padding: "5px 10px", fontSize: "12px", textAlign: "center"}}>
                                                        <h6>Signature</h6>
                                                        <hr/>
                                                    </div>
                                                    <div id="imageContainer"></div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: "25px"}}>
                                            <Col md={5} xs={12}>
                                                {/* Title */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            <span className="asterisk">*&nbsp;</span>
                                                            Title
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="title" 
                                                            className="form-control"
                                                            value={user_data.title} 
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* First name */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            <span className="asterisk">*&nbsp;</span>
                                                            First Name
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="first_name" 
                                                            className="form-control"
                                                            value={user_data.first_name} 
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Last name */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            <span className="asterisk">*&nbsp;</span>
                                                            Last Name
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="last_name" 
                                                            className="form-control"
                                                            value={user_data.last_name} 
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Middle name */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            <span className="asterisk">*&nbsp;</span>
                                                            Middle Name
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="middle_name" 
                                                            className="form-control"
                                                            value={user_data.middle_name} 
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Username */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            <span className="asterisk">*&nbsp;</span>
                                                            Username
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="username" 
                                                            className="form-control"
                                                            value={user_data.username} 
                                                            onChange={handleChange} 
                                                            disabled
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Email */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            <span className="asterisk">*&nbsp;</span>
                                                            Email
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="email" 
                                                            name="email" 
                                                            className="form-control"
                                                            value={user_data.email} 
                                                            onChange={handleChange} 
                                                            disabled
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Phone number */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Phone Number
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="telephone" 
                                                            className="form-control"
                                                            value={user_data.telephone}
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Gender */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Gender
                                                            </span>
                                                        </div>
                                                        <select 
                                                            className="form-control" 
                                                            name="gender" 
                                                            value={user_data.gender}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="select" >-- Select --</option>
                                                            <option value="male" >Male</option>
                                                            <option value="female" >Female</option>
                                                            <option value="prefer_not_to_say">Prefer not to say</option>
                                                            <option value="others" >Others</option>
                                                        </select>
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Date of Birth */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Date of Birth
                                                            </span>
                                                        </div>
                                                            <ReactInputDateMask  
                                                                mask='dd/mm/yyyy' 
                                                                showMaskOnFocus={false}  
                                                                className="form-control" 
                                                                name="dob" 
                                                                value={user_data.dob} 
                                                                onChange={e => handleChangeDOB(e)}
                                                                showMaskOnHover={true} 
                                                                placeholder="enter date"
                                                                // id={"document_date-"+i}
                                                            />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={1} xs={12}></Col>
                                            <Col md={5} xs={12}>
                                                
                                                
                                                {/* Nationality */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Nationality
                                                            </span>
                                                        </div>
                                                        <select 
                                                            className="form-control" 
                                                            value={user_data.nationality}
                                                            name="nationality" 
                                                            onChange={handleChange} 
                                                        >
                                                            {countries && countries.map((country) => (
                                                                <option 
                                                                    key={country.id}
                                                                    value={country.country_name}
                                                                >{country.country_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Country */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Country
                                                            </span>
                                                        </div>
                                                        <select 
                                                            className="form-control" 
                                                            value={user_data.country_of_residence}
                                                            name="country_of_residence" 
                                                            onChange={handleChange} 
                                                        >
                                                            {countries && countries.map((country, index) => (
                                                                <option 
                                                                    key={index}
                                                                    value={country.country_name}
                                                                >{country.country_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* District Province State of Origin*/}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            District/Province/State of Origin
                                                            </span>
                                                        </div>
                                                        <select 
                                                            className="form-control" 
                                                            value={user_data.district_province_state}
                                                            name="district_province_state" 
                                                            onChange={handleChange} 
                                                        >
                                                            {states && states.map((state, index) => (
                                                                <option 
                                                                    key={index}
                                                                    value={state.state_name}
                                                                >{state.state_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* District Province State of Origin*/}
                                                {/* <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            District/Province/State of Origin
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="district_province_state_of_origin"
                                                            className="form-control"
                                                            value={user_data.district_province_state}
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            
                                                {/* Local Government of Origin */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Local Government of Origin
                                                            </span>
                                                        </div>
                                                        <select 
                                                            className="form-control" 
                                                            value={user_data.lga_of_origin}
                                                            name="lga_of_origin" 
                                                            onChange={handleChange} 
                                                        >
                                                            {lgas && lgas.map((lga, index) => (
                                                                <option 
                                                                    key={index}
                                                                    value={lga}
                                                                >{lga}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Address */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            <span className="asterisk">*&nbsp;</span>
                                                            Contact Address
                                                            </span>
                                                        </div>
                                                        <textarea 
                                                            className="form-control" 
                                                            rows={3} 
                                                            name="contact_address"
                                                            value={user_data.contact_address}
                                                            onChange={handleChange} 
                                                            placeholder="Enter ..." 
                                                            // defaultValue={""} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Field Activity */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Field Activity
                                                            </span>
                                                        </div>
                                                        <select 
                                                            className="form-control" 
                                                            value={user_data.field_activity}
                                                            name="field_activity" 
                                                            onChange={handleChange} 
                                                        >
                                                            {field_activities && field_activities.map((field_activity, index) => (
                                                                <option 
                                                                    key={index}
                                                                    value={field_activity}
                                                                >{field_activity}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Degrees Obtained */}
                                                <div className="form-group row">
                                                <div className="input-group mb-3 row">
                                                    <label htmlFor="degrees_obtained" className="col-sm-12 col-form-label form-label">Degrees Obtained:</label>
                                                    <div className="col-sm-12">
                                                        <div className="col-sm-12">
                                                            <input 
                                                                type="checkbox" 
                                                                name="bachelor_of_science_technology" 
                                                                placeholder="Enter value..." 
                                                                value='Bachelor of Science/Technology'
                                                                onChange={handleChangeDegreesObtained} 
                                                                // checked={props.profileDetails.student_data1.disability_dyslexic === "true" ?  true : false}
                                                                checked={bscChecked}
                                                                // className={'reg-input'} 
                                                                className="form-check-input"
                                                            />
                                                            <label htmlFor="bachelor_of_science_technology" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Bachelor of Science/Technology</label>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <input 
                                                                type="checkbox" 
                                                                name="master_of_science_technology" 
                                                                placeholder="Enter value..." 
                                                                value='Master of Science/Technology'
                                                                onChange={handleChangeDegreesObtained} 
                                                                checked={mscChecked}
                                                                // className={'reg-input'} 
                                                                className="form-check-input"
                                                            />
                                                            <label htmlFor="master_of_science_technology" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Master of Science/Technology</label>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <input 
                                                                type="checkbox" 
                                                                name="doctor_of_philosophy" 
                                                                placeholder="Enter value..." 
                                                                value='Doctor of Philosophy'
                                                                onChange={handleChangeDegreesObtained} 
                                                                checked={drChecked}
                                                                // className={'reg-input'} 
                                                                className="form-check-input"
                                                            />
                                                            <label htmlFor="doctor_of_philosophy" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Doctor of Philosophy</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>

                                                {/* <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Local Government of Origin
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="lga_of_origin"
                                                            className="form-control"
                                                            value={user_data.lga_of_origin}
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </Col>
                                        </Row>

                                        <button type="submit" class="btn btn-block bg-gradient-success btn-sm">Update User</button>
                                    </form>

                                    <hr style={{ marginBottom: "50px" }}></hr>

                                    <label>Qualifications</label><hr></hr>
                                    <Row style={{marginBottom: "25px"}}>
                                        <Col xs={12}>
                                            <table id="example1" className="table table-hover table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>S/N</th>
                                                        <th>Qualification Name</th>
                                                        <th>Qualification Daate</th>
                                                        <th>View</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                    {document_data && document_data.map((document) => {
                                                        
                                                        return(
                                                            <tr  
                                                                // data-toggle="modal" 
                                                                // data-target="#view-document" 
                                                                style={{fontSize: "14px"}} 
                                                            >
                                                                <td className="mailbox-name">{++y}</td>
                                                                <td className="mailbox-name">{document.doc_name}</td>
                                                                <td className="mailbox-name">
                                                                    {document.doc_date}
                                                                </td>
                                                                <td className="mailbox-name">
                                                                    {/* <Document
                                                                        file={document.document}
                                                                        onLoadSuccess={onDocumentLoadSuccess}
                                                                    >
                                                                        <Page pageNumber={pageNumber} />
                                                                    </Document>
                                                                    <img src={document.document} /> */}
                                                                    <button 
                                                                        type="button" 
                                                                        className="btn btn-primary" 
                                                                        onClick={() => viewDocument(document.document)}
                                                                    ><i className="fas fa-save"></i> View</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>

                                    <label>Referees </label><hr></hr>
                                    <Row style={{marginBottom: "25px"}}>
                                        <Col xs={12}>
                                            <table id="example1" className="table table-hover table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>S/N</th>
                                                        <th>Referee Name</th>
                                                        <th>Referee Email</th>
                                                        <th>Referee Phone Number</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                    {ref_data && ref_data.map((ref) => {
                                                        
                                                        return(
                                                            <tr  
                                                                data-toggle="modal" 
                                                                // data-target="#view-user" 
                                                                style={{fontSize: "14px"}} 
                                                            >
                                                                <td className="mailbox-name">{++z}</td>
                                                                <td className="mailbox-name">{ref.ref_name}</td>
                                                                <td className="mailbox-name">
                                                                    {ref.ref_email}
                                                                </td>
                                                                <td className="mailbox-name">
                                                                    {ref.ref_phone}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <button 
                                        class="btn btn-danger btn-sm"
                                        style={{float: "right"}}
                                        onClick={() => deleteUser(user_data.username)}
                                    >Delete</button>
                                    {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                                    { user_data.status == 1 ?
                                        <button 
                                            type="submit" 
                                            class="btn bg-gradient-success btn-sm"
                                            onClick={() => approveApp(user_data.username)}
                                            style={{float: "right"}}
                                        >Approve Application</button> : 
                                        <button 
                                            type="submit" 
                                            class="btn bg-gradient-success btn-sm"
                                            style={{float: "right"}}
                                            disabled={true}
                                        >Application Approved</button>
                                    }
                                </div>
                                </div>
                                {/* /.modal-content */}
                            </div>
                            {/* /.modal-dialog */}
                        </div>
                        {/* View User modal */}

                    </section>

                </div>
            <Footer/>
        </div>
    )
}
