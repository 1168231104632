import { GET_TEACHER_DETAILS, UPDATE_TEACHER_DETAILS, GET_ASSIGNED_TEACHER, GET_TEACHERS, GET_MY_TEACHERS } from "../ActionTypes";

const INIT_VALUES = {
    teacher: [],
    from: "",
    to: "",
    total: "",
    prev_page: "",
    next_page: "",
    assigned_teachers: [],
    teachers: []
};

export const TeacherReducer = (state = INIT_VALUES, action) => {
    
    switch (action.type) {
        case GET_TEACHER_DETAILS:
            return { ...state, teacher: action.payload };
        
        case UPDATE_TEACHER_DETAILS:
            return {
                ...state,
                teacher: action.payload.manager_data, 
            }  
        
        case GET_TEACHERS:
            if(action.payload.from) {
                return { 
                    ...state, 
                    teachers: action.payload.student_data, 
                    from: action.payload.from,
                    to: action.payload.to, 
                    total: action.payload.total, 
                    prev_page: action.payload.prev_page_url, 
                    next_page: action.payload.next_page_url, 
                };
            }else {
                return { 
                    ...state, 
                    teachers: action.payload.student_data
                };
            }
        
        case GET_ASSIGNED_TEACHER:
            return { ...state, assigned_teachers: action.payload };

        case GET_MY_TEACHERS:
            if(action.payload.from) {
                return { 
                    ...state, 
                    teachers: action.payload.student_data, 
                    from: action.payload.from,
                    to: action.payload.to, 
                    total: action.payload.total, 
                    prev_page: action.payload.prev_page_url, 
                    next_page: action.payload.next_page_url, 
                };
            }else {
                return { 
                    ...state, 
                    teachers: action.payload.student_data
                };
            }

        default:
            return state;
    }
}


